import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import lowerCase from 'lodash/lowerCase'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'
import debounce from 'lodash/debounce'

// Base Page
import BasePage from '@/pages/Base'

// Services
import CityServices from '@/services/City'
import ProvinceServices from '@/services/Province'
import CountryService from '@/services/Country'
import moment from 'moment'

@Component({})
export default class CityList extends BasePage {
  constructor() {
    super()
  }

  listProductsDialog: boolean = false
  listProductsObject: any = {}
  id_user: number = 0
  allTotalProducts: number = 0
  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search By Name...'
  searchHint: string = ''
  searchIcon: string = 'search'

  // Table
  tableHeaders: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'id',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = true

  cityDialog: boolean = false
  isEdit: boolean = false
  cityDataDialog: any = {}

  countries: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null
  }

  provinces: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null
  }

  selectedDelete: any = {}
  selectedIdProvince: number = 0
  showDeleteModal: boolean = false

  filterCountry: any = {
    item: [],
    selected: null,
    keyword: null,
    label: 'Filter Country...',
    hint: 'Apply Country Filter Here',
    icon: 'mdi-filter-variant',
    loading: false
  }

  filterProvince: any = {
    item: [],
    selected: null,
    keyword: null,
    label: 'Filter Province...',
    hint: 'Apply Province Filter Here',
    icon: 'mdi-filter-variant',
    loading: false
  }

  selectedCountry: string = ''
  selectedProvince: string = ''

  searchProvinces: Function
  searchCountries: Function
  searchFilterCountry: Function
  searchFilterProvince: Function

  async mounted() {
    this.getFilterCountry()
    this.getFilterProvince()
    this.searchProvinces = debounce(this.getProvinces, 500)
    this.searchCountries = debounce(this.getCountries, 500)
    this.searchFilterCountry = debounce(this.getFilterCountry, 500)
    this.searchFilterProvince = debounce(this.getFilterProvince, 500)
  }

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getCities()
  }

  @Watch('filterCountry.keyword')
  async onFilterCountry() {
    await this.searchFilterCountry()
  }

  @Watch('filterProvince.keyword')
  async onFilterProvince() {
    await this.searchFilterProvince()
  }

  @Watch('countries.keyword')
  async onChangedCountry() {
    await this.searchCountries()
    await this.searchProvinces()
  }

  @Watch('provinces.keyword')
  async onChangedProvinces() {
    await this.searchProvinces()
  }

  async onClearSearch() {
    this.searchInput = ''
    this.getCities()
  }

  async onClearFilterCountry() {
    this.filterCountry.selected = null
  }

  async onClearFilterProvince() {
    this.filterProvince.selected = null
  }

  async getCities() {
    try {
      this.tableLoading = true
      const opts = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy,
          'filter[name][like]': ''
        }
      }
      if (this.searchInput != '') {
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.searchInput
        }
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      if (this.filterCountry.selected) {
        var textFilter = 'filter[country_id][is]'
        opts.params[textFilter] = this.filterCountry.selected.id
      } else {
        var textFilter = 'filter[country_id][is]'
        delete opts.params[textFilter]
      }

      if (this.filterProvince.selected) {
        var textFilter = 'filter[province_id][is]'
        opts.params[textFilter] = this.filterProvince.selected.id
      } else {
        var textFilter = 'filter[province_id][is]'
        delete opts.params[textFilter]
      }

      const response = await CityServices.getCities(opts)
      const responseData = response.data.data
      const responseMeta = response.data.meta
      this.tableItems = []
      forEach(responseData, dataProvince => {
        this.tableItems.push(dataProvince.attributes)
      })
      this.tableTotalItems = responseMeta.pagination.total
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getFilterCountry() {
    try {
      this.filterCountry.loading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10
        }
      }

      if (!isEmpty(this.filterCountry.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.filterCountry.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.filterCountry.item = []
      for (const dataCountry of responseData) {
        this.filterCountry.item.push(dataCountry.attributes)
      }
      this.filterCountry.item = sortBy(this.filterCountry.item, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.filterCountry.loading = false
    }
  }

  async getFilterProvince() {
    try {
      this.filterProvince.loading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10
        }
      }

      if (!isEmpty(this.filterProvince.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.filterProvince.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      if (this.filterCountry.selected) {
        var textFilter = 'filter[country_id][is]'
        opts.params[textFilter] = this.filterCountry.selected.id
      } else {
        var textFilter = 'filter[country_id][is]'
        delete opts.params[textFilter]
      }

      const response = await ProvinceServices.getProvinces(opts)
      const responseData = response.data.data
      this.filterProvince.item = []
      for (const dataProvince of responseData) {
        this.filterProvince.item.push(dataProvince.attributes)
      }
      this.filterProvince.item = sortBy(this.filterProvince.item, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.filterProvince.loading = false
    }
  }

  async getCountries() {
    try {
      this.countries.loading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253,
          'filter[name][like]': ''
        }
      }
      if (!isEmpty(this.countries.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.countries.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      this.countries.items = []
      for (const dataCountry of responseData) {
        this.countries.items.push(dataCountry.attributes)
      }
      this.countries.items = sortBy(this.countries.items, 'name')
      if (this.cityDataDialog.country_id) {
        await this.getCountryById(this.cityDataDialog.country_id)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.countries.loading = false
    }
  }

  async getCountryById(id) {
    const response = await CountryService.getCountryById(id)
    this.countries.items.push(response.data.data.attributes)
  }

  async getProvinces() {
    try {
      this.provinces.loading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[name][like]': '',
          'filter[country_id][is]': this.cityDataDialog.country_id
        }
      }
      if (!isEmpty(this.provinces.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.provinces.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }
      const response = await ProvinceServices.getProvinces(opts)
      const responseData = response.data.data
      this.provinces.items = []
      for (const province of responseData) {
        this.provinces.items.push({
          name: province.attributes.name,
          id: province.attributes.id
        })
      }
      this.provinces.items = sortBy(this.provinces.items, 'name')
      if (this.cityDataDialog.province_id) {
        await this.getProvinceById(this.cityDataDialog.province_id)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.provinces.loading = false
    }
  }

  async getProvinceById(id) {
    const response = await ProvinceServices.getOneProvinces(id)
    this.provinces.items.push(response.data.data.attributes)
  }

  openCreateDialog() {
    this.isEdit = false
    this.cityDataDialog = {
      name: null,
      country_id: null,
      province_id: null
    }
    this.cityDialog = true
    this.getCountries()
  }

  closeDialog() {
    this.cityDialog = false
  }

  async addCity() {
    try {
      this.showLoading({ text: 'Saving...' })
      this.cityDialog = false
      const payload = {
        name: this.cityDataDialog.name,
        country_id: this.cityDataDialog.country_id,
        province_id: this.cityDataDialog.province_id
      }

      await CityServices.setCities(payload)
      this.showSnackbar({
        text: 'Saved Successfully!',
        color: 'green',
        timeout: 1500
      })
      this.getCities()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openDeleteModal(props) {
    this.selectedDelete = props.item
    this.showDeleteModal = true
  }

  async deleteCity() {
    try {
      this.showLoading({ text: 'Saving...' })
      this.showDeleteModal = false
      const response = await CityServices.deleteCities(this.selectedDelete.id)
      this.showSnackbar({
        text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
        color: response.status === 200 ? 'green' : 'red',
        timeout: 1500
      })
      this.getCities()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async openEditDialog(props) {
    this.isEdit = true
    this.selectedIdProvince = props.item.id
    this.cityDataDialog = {
      name: props.item.name,
      country_id: props.item.country_id,
      province_id: props.item.province_id
    }
    this.cityDialog = true
    await this.getCountries()
  }

  async updateCity() {
    try {
      this.showLoading({ text: 'Saving...' })
      this.cityDialog = false
      const payload = {
        name: this.cityDataDialog.name,
        country_id: this.cityDataDialog.country_id,
        province_id: this.cityDataDialog.province_id
      }

      await CityServices.updateCities(this.selectedIdProvince, payload)
      this.showSnackbar({
        text: 'Saved Successfully!',
        color: 'green',
        timeout: 1500
      })
      this.getCities()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async onFilterCountryChange() {
    if (this.filterCountry.selected) {
      this.selectedCountry = this.filterCountry.selected.name + ' > '
    } else {
      this.selectedCountry = ''
    }
    await this.getCities()
    await this.getFilterProvince()
  }

  async onFilterProvinceChange() {
    if (this.filterProvince.selected) {
      this.selectedProvince = this.filterProvince.selected.name + ' > '
    } else {
      this.selectedProvince = ''
    }
    await this.getCities()
  }
}
